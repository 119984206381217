import { EARTH_RADIUS } from "./constants"

function latLonToPosition(latitude, longitude) {
  // Calculate position
  const phi = (90 - latitude) * (Math.PI / 180);
  const theta = (longitude + 180) * (Math.PI/180);
  const R = 1.1 * EARTH_RADIUS;
  return [
    -R * Math.sin(phi) * Math.cos(theta),
    R * Math.cos(phi),
    R * Math.sin(phi) * Math.sin(theta)
  ]
}

export { latLonToPosition }
