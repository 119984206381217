import React, { useEffect, useRef, useState  } from "react"
import { useFrame, useLoader } from "react-three-fiber"
import * as THREE from "three"

import { EARTH_RADIUS } from "./constants"

export default function Earth( props ) {
  // Textures
  const clouds = useLoader(THREE.TextureLoader, "images/clouds-large.jpg")
  const map = useLoader(THREE.TextureLoader, "images/earth-large.jpg")
  const bumpMap = useLoader(THREE.TextureLoader, "images/bump-large.jpg")

  // Animate clouds
  const earthMesh = useRef()
  const cloudMesh = useRef()
  useFrame(() => {
    cloudMesh.current.rotation.x += 0.00008;
    cloudMesh.current.rotation.y += 0.0004;
  });

  return (
    <group>
      // Earth
      <mesh ref={earthMesh} {...props}>
        <sphereBufferGeometry attach="geometry"
          args={[EARTH_RADIUS, 64, 64]} />
        <meshStandardMaterial attach="material" 
          bumpMap={bumpMap} bumpScale={0.2}
          displacementMap={bumpMap} displacementScale={0.2}
          map={map} />
      </mesh>
      // Clouds
      <mesh ref={cloudMesh}>
        <sphereBufferGeometry attach="geometry"
          args={[1.03 * EARTH_RADIUS, 64, 64]} />
        <meshStandardMaterial attach="material" 
          transparent alphaMap={clouds}
          color='white' opacity={0.9} />
      </mesh>
    </group>
  )
}

