/*eslint-disable*/
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from "@material-ui/icons/Instagram";
import React from "react";
import styles from "./../../assets/jss/headerLinksStyle.js";
import Button from "./Button.js";
import ShareIcon from "@material-ui/icons/Share";
import MailIcon from "@material-ui/icons/Mail";
import Hidden from "@material-ui/core/Hidden";

import Projects from "../Projects/Projects";
import About from "../About/About";
import Share from "../Share/Share";
import Newsfeed from "../Newsfeed/Newsfeed";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [openProjects, setOpenProjects] = React.useState(false);
  const [openNewsfeed, setOpenNewsfeed] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickShareOpen = () => {
    setOpenShare(true);
  };
  const handleClickProjectsOpen = () => {
    setOpenProjects(true);
  };
  const handleClickNewsfeedOpen = () => {
    setOpenNewsfeed(true);
  };
  const handleClickAboutOpen = () => {
    setOpenAbout(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };
  const handleCloseProjects = () => {
    setOpenProjects(false);
  };
  const handleCloseNewsfeed = () => {
    setOpenNewsfeed(false);
  };
  const handleCloseAbout = () => {
    setOpenAbout(false);
  };

  return (
    <>
      <List className={classes.list}>
        {/* <ListItem className={classes.listItem}>
          <Button
            href="#"
            color="transparent"
            onClick={handleClickNewsfeedOpen}
            className={classes.navLink}
          >
            Climate News
          </Button>
        </ListItem> */}
        <ListItem className={classes.listItem}>
          <Button
            href="#"
            // target="_blank"
            color="transparent"
            onClick={handleClickProjectsOpen}
            // className={classes.navLink + " " + classes.navLinkActive}
            className={classes.navLink}
          >
            Eco Projects
          </Button>
        </ListItem>
        {/* <ListItem className={classes.listItem}>
          <Button
            href="#"
            color="transparent"
            onClick={handleClickProjectsOpen}
            className={classes.navLink}
          >
            About
          </Button>
        </ListItem> */}

        <ListItem className={classes.listItem}>
          <Button
            // href="https://mailchi.mp/dcdfabdae657/get-climate-news-that-matters"
            href="#"
            // target="_blank"
            color="transparent"
            // round
            onClick={handleClickOpen}
            // className={classes.navLink + " " + classes.navLinkActive}
            className={classes.navLink}
          >
            About
          </Button>
        </ListItem>

        {/* Show footer links in drawer on mobile */}
        <Hidden mdUp>
          {/* <ListItem className={classes.listItem}>
            <Button
              href="https://mailchi.mp/dcdfabdae657/get-climate-news-that-matters"
              // target="_blank"
              target="_blank"
              color="transparent"
              onClick={handleClickOpen}
              // className={classes.navLink + " " + classes.navLinkActive}
              className={classes.navLink}
            >
              Newsletter
            </Button>
          </ListItem> */}

          {/* <ListItem className={classes.listItem}>
            <Button
              href="#"
              color="transparent"
              onClick={handleClickOpen}
              className={classes.navLink}
            >
              About
            </Button>
          </ListItem> */}

          <ListItem className={classes.listItem}>
            <Button
              href="mailto:hello@soundsofearth.eco"
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              Contact
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="https://www.iubenda.com/privacy-policy/40447166"
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              Privacy
            </Button>
          </ListItem>

          {/* <ListItem className={classes.listItem}>
            <Button
              href="https://www.instagram.com/sounds_of_earth.eco/"
              color="transparent"
              target="_blank"
              className={classes.navLink}
            >
              <InstagramIcon className={classes.icons} />
              Instagram
            </Button>
          </ListItem> */}

          <ListItem className={classes.listItem}>
            <Button
              href="#"
              // target="_blank"
              onClick={handleClickShareOpen}
              color="transparent"
              className={classes.navLink}
            >
              <ShareIcon className={classes.icons} />
              Share
            </Button>
          </ListItem>
        </Hidden>
      </List>
      <About open={open} handleClose={handleClose} />
      <Share open={openShare} handleClose={handleCloseShare} />
      <Projects open={openProjects} handleClose={handleCloseProjects} />
      <Newsfeed open={openNewsfeed} handleClose={handleCloseNewsfeed} />
    </>
  );
}
