import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

// import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
// import ThemeProvider from "@material-ui/core/styles";

import React from "react";
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import Globe from "../components/Globe/Globe";
import theme from "../theme";
import Footer from "../components/Footer/Footer.js";

// import Toast from "../components/Toast";

export default class Home extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme.theme}>
        {/* <Toast /> */}

        <div
          style={{
            backgroundColor: "#1976d2",
            backgroundSize: "contain",
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          {/* nav header */}
          <div
            style={{
              width: "100vw",
              height: "10vh",
              //   marginTop: "10px",
            }}
          >
            {/* <Header /> */}
            <Header
              color="transparent"
              brand="Sounds of Earth"
              rightLinks={<HeaderLinks />}
              fixed
              changeColorOnScroll={{
                height: 400,
                color: "white",
              }}
            />
          </div>
          <div
            style={{
              width: "100vw",
              maxWidth: "100vh",
              height: "100vw",
              maxHeight: "100vh",
              margin: "0 auto",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              display: "flex",
              placeContent: "center",
              placeItems: "center",
            }}
          >
            <Globe />
            <Footer />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
