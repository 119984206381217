import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import "./globe-audio-player.css";

import play from "./globe-audio-player-images/play.png";
import previous from "./globe-audio-player-images/previous.png";
import next from "./globe-audio-player-images/next.png";
import volume from "./globe-audio-player-images/volume.png";

// For info on layouts see https://static.hanzluo.com/react-h5-audio-player-storybook/index.html?path=/docs/layouts-advanced--stacked
export default function GlobeAudioPlayer({ region, ...props }) {
  const isMobile = useMediaQuery("(max-width:480px)");
  const style = {
    position: "absolute",
    bottom: 0,
    left: 0,
  };
  if (isMobile) {
    style.width = "100%";
    style.textAlign = "center";
    // Test
    // style.marginBottom = "0";
    // style.position = "fixed";
    // style.bottom = 0;
    // style.left = 0;
  } else {
    style.margin = 20;
    style.width = 250;
    style.borderRadius = 5;
  }

  const customIcons = {
    play: <img src={play} alt="Play" />,
    previous: <img src={previous} alt="Previous" />,
    next: <img src={next} alt="Next" />,
    volume: <img src={volume} alt="Volume" />,
  };

  return (
    <div>
      <AudioPlayer
        style={style}
        {...props}
        header={region && region.description}
        src={region && region.sound}
        customProgressBarSection={[RHAP_UI.MAIN_CONTROLS]}
        customControlsSection={isMobile ? [] : [RHAP_UI.VOLUME_CONTROLS]}
        customIcons={customIcons}
        showSkipControls={true}
        showJumpControls={false}
        volume={0.3}
      />
    </div>
  );
}
