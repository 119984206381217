import React, { useEffect, useState, Suspense } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Canvas } from "react-three-fiber";
import "pepjs";

import Earth from "./Earth";
import GlobeAudioPlayer from "./GlobeAudioPlayer";
import GlobeControls from "./GlobeControls";
import Pin from "./Pin";
import { CAMERA_D } from "./constants";
import { Hidden } from "@material-ui/core";
import MobileFooter from "../Footer/MobileFooter";

export default function Globe() {
  const isMobile = useMediaQuery("(max-width:480px)");
  const style = {
    position: "absolute",
    textAlign: "center",
    color: "rgba(255, 255, 255, 0.5)",
    //fontSize: 60,
    fontWeight: 900,
    userSelect: "none",
    pointerEvents: "none",
    userInteraction: "none",
    textTransform: "uppercase",
  };
  if (isMobile) {
    style.fontSize = 60;
  } else {
    style.fontSize = 110;
  }
  // Get data from API
  // TODO: switch to live API once publicly available
  const [regions, setRegions] = useState({ results: [] });
  useEffect(
    () =>
      fetch("/regions.json")
        .then((res) => res.json())
        .then(setRegions),
    []
  );

  const [hoveredRegion, setHoveredRegion] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [grabbed, setGrabbed] = useState(false);

  const skipTrack = (direction) => {
    const tracks = regions.results;
    let i = tracks.indexOf(selectedRegion);

    // If nothing selected, ignore input
    if (i === -1) {
      return;
    }

    // Switch track and loop around
    i += direction;
    if (i < 0) {
      i = tracks.length - 1;
    } else if (i >= tracks.length) {
      i = 0;
    }

    setSelectedRegion(regions.results[i]);
  };

  // Note that 'touch-action="none"' in conjuction with pepjs
  // is needed to make events work on broken browsers
  // See: https://github.com/pmndrs/react-three-fiber/issues/323
  return (
    <>
      <Canvas
        touch-action="none"
        camera={{ fov: 45, position: [0, 0, CAMERA_D] }}
        colorManagement
        pixelRatio={window.devicePixelRatio}
        style={{
          outline: "none",
          border: "none",
          background:
            "radial-gradient(at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 70%",
          cursor: grabbed ? "grabbing" : hoveredRegion ? "pointer" : "grab",
          // outline: "none", //Doesn't remove the blue outline around the globe on Chrome
        }}
      >
        <ambientLight intensity={0.2} />
        <pointLight position={[-10, -10, -10]} />
        <Suspense fallback={null}>
          <Earth
            onPointerDown={() => setGrabbed(true)}
            onPointerUp={() => setGrabbed(false)}
          />
          {regions.results.map((region, key) => (
            <Pin
              key={key}
              onPointerUp={() => setSelectedRegion(region)}
              onHover={(hovered) => setHoveredRegion(hovered && region)}
              longitude={region.location.coordinates[0]}
              latitude={region.location.coordinates[1]}
            />
          ))}
        </Suspense>
        <GlobeControls
          region={selectedRegion}
          autoRotate
          autoRotateSpeed={0.2}
          dampingFactor={0.05}
          enableDamping
          enablePan={false}
          enableZoom={false}
          minPolarAngle={0.2 * Math.PI}
          maxPolarAngle={0.8 * Math.PI}
          panSpeed={0.1}
          rotateSpeed={0.2}
        />
      </Canvas>
      <div
        style={style}
        // style={{
        //   position: "absolute",
        //   textAlign: "center",
        //   color: "rgba(255, 255, 255, 0.5)",
        //   fontSize: 60,
        //   // fontWeight: 600,
        //   fontWeight: 900,
        //   userSelect: "none",
        //   pointerEvents: "none",
        //   userInteraction: "none",
        //   textTransform: "uppercase",
        // }}
      >
        {hoveredRegion && hoveredRegion.name}
      </div>
      <MobileFooter />
      <GlobeAudioPlayer
        region={selectedRegion}
        onClickNext={() => skipTrack(1)}
        onClickPrevious={() => skipTrack(-1)}
      />
    </>
  );
}
