import React, { useEffect, useState, useCallback } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import PublicIcon from "@material-ui/icons/Public";
import Grid from "@material-ui/core/Grid";

import ReadMoreReadLess from "./ReadMoreReadLess";
import serverURL from "../../constants/serverURL";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    // maxWidth: 500,
    // display: "flex",
    marginBottom: "12px",
    // position: "relative",
  },
  details: {
    // display: "flex",
    flex: "1 0 65%",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    // flex: "1 0 20%",
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    flex: "1 0 auto",
    textDecoration: "none",
    color: "inherit",
    fontSize: 14,
    fontWeight: "bold",
    // textTransform: "uppercase",
    // margin: theme.spacing(2, 0),
  },
  media: {
    height: 0,
    // paddingTop: "56.25%", // 16:9
    paddingTop: "45.25%", // 16:9
  },
  section: {
    margin: theme.spacing(1, 0),
    textAlign: "center",
  },
  section1: {
    margin: theme.spacing(2, 0),
  },
  section2: {
    // margin: theme.spacing(2, 0),
    marginTop: "16px",
  },
  description: {
    // color: "textSecondary",
    // margin: theme.spacing(2, 0),
    margin: theme.spacing(1.5, 0),
    flex: "1 0 auto",
    textDecoration: "none",
    color: "inherit",
    fontSize: 13,
  },
  support: {
    textAlign: "center",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// export default function Projects() {
function Projects({ open, handleClose }) {
  // const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();

  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [projects, setProjects] = useState({ results: [] });
  // useEffect(
  //   () =>
  //     fetch("./projects.json")
  //       // fetch(`${serverURL}/projects`)
  //       .then((res) => res.json())
  //       .then(setProjects),
  //   []
  // );

  const fetchAPI = useCallback(async () => {
    // let response = await fetch(`${serverURL}/projects`);
    let response = await fetch("./projects.json");
    response = await response.json();
    setProjects(response);
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <div>
      {/* <h1>Projects</h1>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open project details
      </Button> */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Ecological Projects
        </DialogTitle>
        <DialogContent dividers>
          <ul
            style={{
              display: "inline",
            }}
          >
            {projects.results.map((project, key) => {
              return (
                <>
                  <Card className={classes.root}>
                    <CardMedia
                      className={classes.media}
                      image={project.project_image.full_size}
                    />
                    <CardContent>
                      <Typography className={classes.title} variant="h5">
                        {project.title} - {project.country}
                        {/* <h2 key={project.id}>{project.title}</h2>{" "} */}
                      </Typography>
                      <Typography paragraph className={classes.description}>
                        <ReadMoreReadLess
                          charLimit={150}
                          readMoreText={"Read More"}
                          readLessText={"Read Less"}
                        >
                          {project.description}
                        </ReadMoreReadLess>
                      </Typography>
                      <div className={classes.section2}>
                        <Divider variant="middle" />
                      </div>
                      {/* <div key={key}>
                  </div> */}
                    </CardContent>
                    <CardActions>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} className={classes.support}>
                          <Button
                            size="small"
                            color="primary"
                            target="_blank"
                            href={project.url}
                            startIcon={<PublicIcon />}
                          >
                            Learn More
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </>
              );
            })}
          </ul>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default Projects;
