/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import swipe from "../../assets/svg/swipe.svg";
import styles from "../../assets/jss/mobileFooterStyles";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  return (
    <>
      <Hidden mdUp>
        <footer className={footerClasses}>
          <div className={classes.content}>
            <img src={swipe} alt="hand" />
            <p className={classes.footerText}>
              Touch and drag to rotate the globe
            </p>
          </div>
        </footer>
      </Hidden>
    </>
  );
}
Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
