import React, { useRef, useState } from "react"
import { useFrame, useLoader, useThree } from "react-three-fiber"
import * as THREE from "three"

import { latLonToPosition } from "./utils"

export default function Pin({latitude, longitude, ...props}) {
  const mesh = useRef()
  const { camera } = useThree()
  const [hovered, setHover] = useState(false)

  const onHover = hovered => {
    setHover(hovered);
    props.onHover(hovered)
  }

  // Always face the camera
	useFrame(() => {
    mesh.current.up.copy( camera.up );
    mesh.current.lookAt( camera.position );
  })

  // Calculate position
  const position = latLonToPosition(latitude, longitude);

  const marker = useLoader(THREE.TextureLoader, "images/marker.png");
  const markerHover = useLoader(THREE.TextureLoader, "images/marker-hover.png");
  const markerSize = 0.1;

  return (
    <mesh
      ref={mesh}
      {...props}
      position={position}
      onPointerOver={(e) => onHover(true)}
      onPointerOut={(e) => onHover(false)}>
      <circleBufferGeometry attach="geometry" args={[markerSize, 32]} />
      <meshBasicMaterial attach="material"
        map={hovered ? marker : markerHover}/>
    </mesh>
  )
}

