import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";

import { EmailIcon, FacebookIcon, RedditIcon, TwitterIcon } from "react-share";

import styles from "../../assets/jss/shareStyles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

function Share({ open, handleClose }) {
  const classes = useStyles();
  const theme = useTheme();

  const shareUrl = "https://www.soundsofearth.eco";
  const title = "Sounds of Earth";

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Share with Friends
        </DialogTitle>
        <DialogContent className={classes.container}>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <ListItem className={classes.listItem}>
                <Tooltip
                  id="twitter"
                  title="Share on Twitter"
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button className={classes.navLink}>
                    <TwitterShareButton
                      url={shareUrl}
                      title={
                        "Listen to relaxing ambient sounds of nature. " + " - "
                      }
                      hashtag={"#naturesounds"}
                      className={classes.navLink}
                    >
                      <TwitterIcon size={36} round={true} />
                    </TwitterShareButton>
                  </Button>
                </Tooltip>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Tooltip
                  id="facebook"
                  title="Share on Facebook"
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button className={classes.navLink}>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={
                        "Listen to relaxing ambient sounds of nature. " +
                        shareUrl
                      }
                      hashtag="#naturesounds"
                      className={classes.navLink}
                    >
                      <FacebookIcon size={36} round={true} />
                    </FacebookShareButton>
                  </Button>
                </Tooltip>
              </ListItem>

              <ListItem className={classes.listItem}>
                <Tooltip
                  id="reddit"
                  title="Share on Reddit"
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button className={classes.navLink}>
                    <RedditShareButton
                      url={
                        "Listen to relaxing ambient sounds of nature. " +
                        shareUrl
                      }
                      title={title}
                      className={classes.navLink}
                    >
                      <RedditIcon size={36} round={true} />
                    </RedditShareButton>
                  </Button>
                </Tooltip>
              </ListItem>

              <ListItem className={classes.listItem}>
                <Tooltip
                  id="email"
                  title="Share via Email"
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button className={classes.navLink}>
                    <EmailShareButton
                      url={shareUrl}
                      subject={"Listen to relaxing ambient sounds of nature"}
                      body={
                        "Sounds of Earth is am ambient audio player that focuses on natural sounds from around the world. You can also find and support verified restoration projects like tree planting."
                      }
                      separator=":: "
                      hashtag="#naturesounds"
                      className={classes.navLink}
                    >
                      <EmailIcon size={36} round={true} />
                    </EmailShareButton>
                  </Button>
                </Tooltip>
              </ListItem>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions justify="center">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Share;
