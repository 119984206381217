import React, { useEffect, useRef } from "react"
import * as THREE from "three"
import { extend, useFrame, useThree } from "react-three-fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import { latLonToPosition } from "./utils"
import { CAMERA_D } from "./constants"

extend({ OrbitControls })
const GlobeControls = ({region, ...props}) => {
  const { gl, camera } = useThree()
  const ref = useRef()

  useFrame(() => {
    if ( camera.targetPosition ) {
      ref.current.enabled = false;
      const delta = camera.targetPosition.clone().sub( camera.position );
      if ( delta.lengthSq() > 0.1 ) {
        // If we have a target position move 10% of the
        // way towards it on each frame, while maintaining
        // a set distance from the globe
        delta.multiplyScalar( 0.1 );
        camera.position.add( delta );
        camera.position.setLength(CAMERA_D);
      } else {
        camera.targetPosition = null;
        ref.current.enabled = true;
      }
    }
    ref.current.update()
  })

  // Whenever the region is changed update the view
  // so that it is focused
  useEffect(() => {
    if (!region) { return }
    const longitude = region.location.coordinates[0];
    const latitude = region.location.coordinates[1];
    const position = new THREE.Vector3(
      ...latLonToPosition(latitude, longitude));
    position.setLength(CAMERA_D);

    // Actual animation will happen in useFrame
    if ( !camera.targetPosition ) {
      camera.targetPosition = new THREE.Vector3();
    }
    camera.targetPosition.copy( position );
  }, [camera, region]);
  return <orbitControls ref={ref} args={[camera, gl.domElement]} {...props} />
}

export default GlobeControls;
