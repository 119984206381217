import React from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Quote from "../../components/Quote/Quote";
// import Button from "@material-ui/core/Button";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import InstagramIcon from "@material-ui/icons/Instagram";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import MusicVideoIcon from "@material-ui/icons/MusicVideo";
// import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "12px",
  },
  details: {
    // display: "flex",
    flex: "1 0 65%",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    // flex: "1 0 20%",
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    flex: "1 0 auto",
    textDecoration: "none",
    color: "inherit",
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  desc: {
    flex: "1 0 auto",
    textDecoration: "none",
    color: "inherit",
    fontSize: 13,
  },
  cover: {
    width: "251px !important",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  emailAddress: {
    color: "#inherit",
    fontWeight: "500",
    fontSize: "11px",
    textTransform: "uppercase",
    textDecoration: "none",
    padding: "5px 0",
  },
  url: {
    textDecoration: "none",
    fontStyle: "oblique",
    marginLeft: "8px",
  },
  socialContainer: {
    textAlign: "center",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function About({ open, handleClose }) {
  // export default function CustomizedDialogs() {
  const classes = useStyles();
  const theme = useTheme();

  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open
      </Button> */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          About Sounds of Earth
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" gutterBottom>
            <Quote
              text="The Earth is the only world known so far to harbor life. There is
              nowhere else, at least in the near future, to which our species
              could migrate. Visit, yes. Settle, not yet. Like it or not, for
              the moment the Earth is where we make our stand."
              author=" Carl Sagan, Astronomer, Planetary Scientist, Cosmologist"
            />
          </Typography>

          <Typography variant="body2" gutterBottom>
            <p>
              Sounds of Earth grew out of a personal need to create a collection
              of ambient sounds of nature to help me relax or meditate and raise
              awareness for the protection and restoration of our ecosystems. We
              need to protect and restore our environment so that future
              generations can also enjoy the natural sights and sounds that we
              take for granted. So please support your local environmental
              projects or the curated ones on this website.
            </p>
            <p>
              <h4>Awards</h4>
              European Regional Development Fund via &nbsp;
              <a
                className={classes.emailAddress}
                href="https://www.sbi.business/si/home"
                target="_blank"
              >
                LSBU Sustainable Innovation
              </a>
              <br />
              Product Hunt - &nbsp;
              <a
                className={classes.emailAddress}
                href="https://www.producthunt.com/posts/sounds-of-earth"
                target="_blank"
              >
                Featured product of the day
              </a>
            </p>
            <p>
              <h4>What's behind the name?</h4>
              In 1977 NASA launched the Voyager 1 and 2 Interstellar missions to
              extend the exploration of the solar system beyond the neighborhood
              of the outer planets to the outer limits of the Sun's sphere of
              influence, and possibly beyond. Aboard both Voyager spacecrafts,
              they included two golden phonograph records intended for other
              intelligent extraterrestrial civilizations. The content of the
              records was selected by a committee chaired by Carl Sagan - the
              most famous Cosmologist and Science Educator of his time. The
              committee's selection included sounds and images that portray the
              diversity of life and culture on planet Earth. A portion of the
              record was reserved for the{" "}
              <i>
                <b>Sounds of Earth </b>
              </i>
              which included natural sounds from volcanoes, wind, rain, animals,
              humans, and more.
            </p>
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            <h4>Want to get in touch?</h4>
            <p>
              No problem. Just click on the email address below or highlight it,
              right-click and copy the address.
            </p>
            <a className={classes.emailAddress} href="mailto:hello@oliguei.com">
              hello@oliguei.com
            </a>
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            <p>
              <i>&copy; 2022 Sounds of Earth</i>
            </p>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default About;
