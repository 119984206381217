// PER ASPERA AD ASTRA
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import logo from './logo.svg';
import "./App.css";
import HomePage from "./pages/HomePage";
import store from "./store";
import { history } from "./utils/historyUtils";

function App() {
  return (
    <Router history={history}>
      <Provider store={store}>
        <Switch>
          <Route exact path="/" component={HomePage} />
        </Switch>
      </Provider>
    </Router>
  );
}

export default App;
