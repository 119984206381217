import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

function configureStore(initialState) {
  let createStoreWithMiddleware;

  const logger = createLogger();
  const middleware = composeWithDevTools(applyMiddleware(thunk, logger));

  createStoreWithMiddleware = compose(middleware);

  return createStoreWithMiddleware(createStore)(rootReducer, initialState);
}

let store = configureStore();

export default store;
