/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";
import hand from "../../assets/svg/hand.svg";
import styles from "../../assets/jss/footerStyles";
import Hidden from "@material-ui/core/Hidden";
// import withWidth from "@material-ui/core/withWidth";
import Tooltip from "@material-ui/core/Tooltip";
// import Button from "../../components/Header/Button.js";
import Button from "./Button.js";
import ShareIcon from "@material-ui/icons/Share";
// import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";

import Share from ".././Share/Share";
import About from ".././About/About";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickShareOpen = () => {
    setOpenShare(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };
  return (
    <>
      <Hidden smDown>
        <footer className={footerClasses}>
          <div className={classes.container}>
            <img src={hand} alt="hand" />
            <p className={classes.footerText}>Grab and spin the globe</p>

            <div className={classes.socialLine}>
              {/* <Button
                href="#"
                onClick={handleClickOpen}
                color="transparent"
              >
                About
              </Button> */}
              <Button
                href="mailto:hello@oliguei.com"
                target="_blank"
                color="transparent"
              >
                Contact
              </Button>
              <Button
                href="https://www.iubenda.com/privacy-policy/40447166"
                target="_blank"
                color="transparent"
              >
                Privacy
              </Button>
              {/* <Tooltip
                id="share"
                title="Follow SoE on Instagram"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  href="https://www.instagram.com/sounds_of_earth.eco/"
                  target="_blank"
                  color="transparent"
                >
                  <InstagramIcon className={classes.icons} />
                </Button>
              </Tooltip> */}

              <Tooltip
                id="share"
                title="Share with Friends"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  href="#"
                  // target="_blank"
                  onClick={handleClickShareOpen}
                  color="transparent"
                >
                  <ShareIcon className={classes.icons} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </footer>
      </Hidden>
      <About open={open} handleClose={handleClose} />
      <Share open={openShare} handleClose={handleCloseShare} />
    </>
  );
}
Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
