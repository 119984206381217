import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#41b578",
      dark: "#2f8256",
    },
    secondary: {
      main: "#272727",
    },
  },
});

export default { theme };
