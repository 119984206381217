import { container, primaryColor } from "../material-kit-react.js";
import tooltip from "../jss/tooltipsStyle";

const footerStyle = {
  list: {
    // ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "#fff",

    right: 0,
    // width: "250px",
    textAlign: "center",

    position: "absolute",
    bottom: 0,
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    //   "&:after": {
    //     width: "calc(100% - 30px)",
    //     content: '""',
    //     display: "block",
    //     height: "1px",
    //     marginLeft: "15px",
    //     backgroundColor: "#e5e5e5",
    //   },
    // },
  },
  block: {
    // color: "inherit",
    color: "#fff",
    // padding: "0.9375rem",
    padding: "1.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    // position: "relative",
    display: "block",

    position: "absolute",
    bottom: 0,
    // left: 0,
    right: 0,
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  footer: {
    // padding: "0.9375rem 0",
    // width: "250px",
    // width: "285px",
    // width: "298px",
    width: "328px",
    textAlign: "center",
    // display: "flex",
    // zIndex: "2",
    // position: "relative",
    position: "absolute",
    // bottom: 0,
    // right: 0,
    bottom: "6px",
    // right: "59px",
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0",
    color: "#FFFFFF",
  },
  content: {
    // justify: "center",
    textAlign: "center",
    paddingBottom: "60px",
    color: "#FFFFFF",
  },
  footerText: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "11px",
    textTransform: "uppercase",
    textDecoration: "none",
  },
  footerLinks: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "11px",
    textTransform: "uppercase",
    textDecoration: "none",
    padding: "5px 0",
  },

  // footerLinks: {
  //   "&:a": {
  //     color: "#FFF",
  //     textDecoration: "none",
  //     fontWeight: "500",
  //     fontSize: "11px",
  //     textTransform: "uppercase",
  //     padding: "5 0",
  //   },
  // },

  // a: {
  //   color: primaryColor,
  //   textDecoration: "none",
  //   backgroundColor: "transparent",
  // },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF",
    },
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    // padding: "10px",
    paddingLeft: "150px",
    width: "auto",
  },
  icon: {
    width: "18px",
    height: "18px",
    // position: "relative",
    position: "absolute",
    top: "3px",
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px",
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
  },

  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
};
export default footerStyle;
