import React, { useEffect, useState, useCallback, useRef } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import PublicIcon from "@material-ui/icons/Public";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import ReadMoreReadLess from "../Projects/ReadMoreReadLess";
import serverURL from "../../constants/serverURL";

import img from "../../assets/images/brazil-eco.jpg";
import Tooltip from "@material-ui/core/Tooltip";
import ShareIcon from "@material-ui/icons/Share";
import Share from ".././Share/Share";
// import styles from "../../assets/jss/shareStyles";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    // maxWidth: 500,
    // display: "flex",
    marginBottom: "12px",
    // position: "relative",
  },
  details: {
    // display: "flex",
    flex: "1 0 65%",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    // flex: "1 0 20%",
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    flex: "1 0 auto",
    textDecoration: "none",
    color: "inherit",
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    // margin: theme.spacing(2, 0),
  },
  media: {
    height: 0,
    // paddingTop: "56.25%", // 16:9
    paddingTop: "45.25%", // 16:9
  },
  section: {
    margin: theme.spacing(1, 0),
    textAlign: "center",
  },
  section1: {
    margin: theme.spacing(2, 0),
  },
  section2: {
    // margin: theme.spacing(2, 0),
    marginTop: "16px",
  },
  snippetContainer: {
    // color: "textSecondary",
    // margin: theme.spacing(2, 0),
    margin: theme.spacing(1.5, 0),
    flex: "1 0 auto",
    textDecoration: "none",
    color: "inherit",
    fontSize: 13,
  },
  catContainer: {
    // textAlign: "center",
    margin: theme.spacing(0, 1),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// export default function Posts() {
function Newsfeed({ open, handleClose }) {
  //   const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();

  //   const [expanded, setExpanded] = React.useState(false);
  //   const [openShare, setOpenShare] = React.useState(false);

  //   const handleExpandClick = () => {
  //     setExpanded(!expanded);
  //   };

  //   const handleClickShareOpen = () => {
  //     setOpenShare(true);
  //   };
  //   const handleCloseShare = () => {
  //     setOpenShare(false);
  //   };

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };
  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);

  const fetchPosts = () => {
    axios.get(`${serverURL}/newsfeed?page=${page}`).then((res) => {
      const result = res.data.results;
      setPosts([...posts, ...result]);
      setPage((prev) => prev + 1);
    });
    console.log("page", page);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div>
      {/* <h1>Newfeed</h1>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open newsfeed
      </Button> */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Climate News & Insights
        </DialogTitle>

        <DialogContent dividers id="scrollableDiv">
          <InfiniteScroll
            dataLength={posts.length}
            next={() => fetchPosts()}
            hasMore={true}
            loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Looks like you've seen all posts.</b>
              </p>
            }
            scrollableTarget="scrollableDiv"
            style={{ overflow: "visible" }}
          >
            {posts.length > 1 &&
              posts.map((post, i) => (
                <ul
                  style={{
                    display: "inline",
                  }}
                >
                  <div>
                    <Card className={classes.root} key={i}>
                      <CardMedia
                        className={classes.media}
                        image={post.image_url}
                        title="Click to read full article"
                        component="a"
                        target="_blank"
                        href={post.link}
                      />
                      <CardContent>
                        <Typography
                          className={classes.title}
                          variant="h5"
                          component="a"
                          target="_blank"
                          href={post.link}
                        >
                          {post.title}
                        </Typography>
                        <Typography
                          paragraph
                          className={classes.snippetContainer}
                        >
                          <ReadMoreReadLess
                            charLimit={150}
                            readMoreText={"Read More"}
                            readLessText={"Read Less"}
                          >
                            {post.snippet}
                          </ReadMoreReadLess>
                        </Typography>
                        <div className={classes.section2}>
                          <Divider variant="middle" />
                        </div>
                      </CardContent>
                      <CardActions>
                        <Grid container direction="row">
                          <Grid item xs={12} className={classes.catContainer}>
                            <Chip
                              size="small"
                              color="primary"
                              label={post.category}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </div>
                </ul>
              ))}
          </InfiniteScroll>
        </DialogContent>
      </Dialog>

      {/* <Share open={openShare} handleClose={handleCloseShare} /> */}
    </div>
  );
}
export default Newsfeed;
